// @ts-nocheck
import { Text } from '@blueprintjs/core';
import { Box, Group, Stack } from '@/components';
import {
  ImportDropzoneField,
  ImportDropzoneFieldProps,
} from '@/containers/Import/ImportDropzoneFile';
import {
  useGetPresignedUrlImage,
  useUploadAttachments,
} from '@/hooks/query/attachments';
import styles from './UploadLogoPopoverContent.module.scss';
import { MIME_TYPES } from '@/components/Dropzone/mine-types';

interface UploadLogoPopoverContentProps {
  initialValue?: string;
  value?: string;
  onChange?: (value: string) => void;
  onUploadedChange?: (value: string) => void;
  dropzoneFieldProps?: ImportDropzoneFieldProps;
}

/**
 * Uploads and list the attachments with ability to delete particular attachment.
 * @param {UploadLogoPopoverContentProps}
 */
export function UploadLogoPopoverContent({
  initialValue,
  value,
  onChange,
  onUploadedChange,
  dropzoneFieldProps,
}: UploadLogoPopoverContentProps) {
  const { data } = useGetPresignedUrlImage(value, { enabled: !!value });

  // Uploads the attachments.
  const { mutateAsync: uploadLogo } = useUploadAttachments({
    onSuccess: (data) => {
      onChange(data.data.data.key);
      onUploadedChange && onUploadedChange(newLocalFiles);
    },
  });

  // Handle change dropzone.
  const handleChangeDropzone = (file: File) => {
    if (!file) {
      onChange(null);
      return;
    }
    const formData = new FormData();
    const key = Date.now().toString();

    formData.append('file', file);
    formData.append('internalKey', key);

    uploadLogo(formData);
  };

  return (
    <div className={styles.content}>
      <div>
        <Text className={styles.label}>Upload Logo</Text>
        <Stack spacing={0}>
          <ImportDropzoneField
            uploadIcon={
              <div>
                {' '}
                {data?.presigned_url && (
                  <img
                    src={data.presigned_url}
                    alt={'organization-logo'}
                    styles={{
                      height: '100px',
                      'object-fit': 'contain',
                    }}
                  />
                )}
              </div>
            }
            value={data?.presigned_url ?? null}
            title={''}
            classNames={{ root: styles.dropzoneRoot }}
            onChange={handleChangeDropzone}
            dropzoneProps={{
              accept: [MIME_TYPES.png, MIME_TYPES.jpeg],
            }}
            {...dropzoneFieldProps}
            maxFiles={1}
            multiple={false}
          />
          <Group className={styles.hintText}>
            <Box>Maximum: 25MB</Box>
          </Group>
        </Stack>
      </div>
    </div>
  );
}
