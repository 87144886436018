// @ts-nocheck

export default {
  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT: 'LOGOUT',
  LOGIN_CLEAR_ERRORS: 'LOGIN_CLEAR_ERRORS',
  RESET: 'RESET',
  SET_EMAIL_VERIFIED: 'SET_EMAIL_VERIFIED',
  SET_AUTH_TOKEN: 'SET_AUTH_TOKEN',
  SET_ORGANIZATIOIN_ID: 'SET_ORGANIZATIOIN_ID',
  SET_TENANT_ID: 'SET_TENANT_ID',
  SET_USER_ID: 'SET_USER_ID',
  SET_LOCALE: 'SET_LOCALE',
};