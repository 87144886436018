// @ts-nocheck
import React from 'react';
import classNames from 'classnames';
import { Icon } from '@/components';

import '@/style/components/BigcapitalLoading.scss';
import logo from '../../dummy-logo01.png';

/**
 * Bigcapital logo loading.
 */
export default function BigcapitalLoading({ className }) {
  return (
    <div className={classNames('bigcapital-loading', className)}>
      <div class="center">
        {/*<Icon icon="bigcapital" height={37} width={228} />*/}
        <img src={logo} width={100}></img>

      </div>
    </div>
  );
}
